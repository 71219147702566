import React from 'react';
import humanizeDuration from 'humanize-duration';

import { formatDate_ShortHuman, formatTime_AmPm } from 'util/Time';
import Button from 'components/common/Button';
import { PlaneIcon } from 'components/common/Icon';
import Pill from 'components/common/Pill';
import FlightOptionUtilityBar from './FlightOptionUtilityBar';

import './FlightOption.scss';

export type OneWayFlight = {
    airline_logo: string[];
    airlines: string[];
    airplanes: string[];
    arrival_airport: string;
    arrival_time: string;
    booking_link: string;
    booking_url?: string;
    departure_airport: string;
    departure_time: string;
    durations: number[];
    total_duration: number;
    flight_numbers: string[];
    layovers: any[];
    legroom: string[];
    overnight: boolean[];
    price: number;
    serp_booking_token: string;
    travel_class: string[];
    utility_costs?: UtilityCosts;
};

export type RoundTripFlight = {
    booking_url: string;
    departure_option: OneWayFlight;
    return_option: OneWayFlight;
    utility_costs?: UtilityCosts;
};

export type UtilityCosts = {
    time_cost_before: number;
    time_cost_after: number;
    stops_preference_cost: number;
    total_costs: number;
    time_cost: number;
};

type FlightOptionProps = {
    option: OneWayFlight | RoundTripFlight;
    isTopPick: boolean;
    allOptions: (OneWayFlight | RoundTripFlight)[];
};

const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3
});

class FlightOption extends React.Component<FlightOptionProps, {}> {
    isRoundTrip = () => {
        const { option } = this.props;
        return 'departure_option' in option;
    }

    getDepartureAirline = () => {
        const { option } = this.props;

        if (this.isRoundTrip()) {
            return (option as RoundTripFlight).departure_option?.airlines[0];
        }

        return (option as OneWayFlight).airlines[0];
    }

    getReturnAirline = () => {
        const { option } = this.props;

        if (this.isRoundTrip()) {
            return (option as RoundTripFlight).return_option?.airlines[0];
        }

        return (option as OneWayFlight).airlines[0];
    }

    getPrice = () => {
        const { option } = this.props;

        if (this.isRoundTrip()) {
            if ((option as RoundTripFlight).departure_option && (option as RoundTripFlight).departure_option.price) {
                const price = (option as RoundTripFlight).departure_option.price;
                return USDollar.format(price);
            } else {
                return 'Price not available';
            }
        }

        if ((option as OneWayFlight).price) {
            const price = (option as OneWayFlight).price;
            return USDollar.format(price);
        } else {
            return 'Price not available';
        }
    }

    renderFlightLeg = (option: OneWayFlight, direction = 'departure') => {
        if (!option) {
            return <></>;
        }

        return (
            <div className="option-details">
                <div className="option-details-title-wrapper">
                    <div>
                        {option?.airline_logo.length
                            ? (<img src={option.airline_logo[0]} alt={option.airlines[0]} className="airline-logo" />)
                            : (<PlaneIcon />)
                        }
                    </div>
                    <div>{formatDate_ShortHuman(option.departure_time)}</div>
                    <div>{direction === 'departure' ? this.getDepartureAirline() : this.getReturnAirline()}</div>
                </div>

                <div className="option-details-body-wrapper">
                    <div>
                        {formatTime_AmPm(option.departure_time)}<br />
                        {option.departure_airport}
                    </div>

                    <div className="duration">
                        <div className="visual"></div>
                        <div>
                            {humanizeDuration(option.total_duration * 60 * 1000)}
                        </div>
                        <div className="layovers">
                            {
                                option.layovers.length
                                    ? `${option.layovers.length} stop(s): ${option.layovers.join(', ')}`
                                    : 'nonstop'
                            }
                        </div>
                    </div>

                    <div>
                        {formatTime_AmPm(option.arrival_time)}<br />
                        {option.arrival_airport}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { option, isTopPick, allOptions } = this.props;

        return (
            <div className="adapter-intention-option">
                <div className="option-price-header">
                    <div className="option-price-header-wrapper">
                        <h3>{this.getPrice()}</h3>
                        <p>{this.isRoundTrip() ? 'round trip' : 'one way'}</p>
                    </div>
                    {isTopPick && <Pill highlighted>Best Match</Pill>}
                </div>

                {this.isRoundTrip() ? (
                    <>
                        {this.renderFlightLeg((option as RoundTripFlight).departure_option)}
                        {this.renderFlightLeg((option as RoundTripFlight).return_option, 'return')}
                    </>
                ) : this.renderFlightLeg(option as OneWayFlight)}

                <FlightOptionUtilityBar option={option} allOptions={allOptions} />

                {option.booking_url && (
                    <Button
                        size="2xl"
                        onClick={() => window.open(option.booking_url, '_blank')}
                        style={{ width: '100%', marginTop: '24px' }}
                    >Book Now</Button>
                )}
            </div>
        );
    }
}

export default FlightOption;