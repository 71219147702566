import React from 'react';
import Notice from 'components/common/Notice';

const UnsupportedOption = ({ }) => {
    return (
        <>
            <Notice text='Unsupported Request' warning />

            <div>
                Adapter is currently unable to support this request.
                If you would like to see this feature added, please let us know using the feedback feature.
            </div>
        </>
    );
};

export default UnsupportedOption;