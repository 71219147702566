import React from 'react';
import { inject, observer } from 'mobx-react';
import { entries } from 'mobx';
import classNames from 'classnames';
import { timeAgo } from 'util/Time';

import { Job } from "models/Job";
import { AlertCircleIcon, CaretRightIcon, AdapterIcon } from 'components/common/Icon';
import Progress from 'components/common/Progress';
import { saveToLocalstorage, KEYS } from 'util/Localstorage';
import withRouter from "hooks/withRouter";

import './ActionLog.scss';

type ActionLogProps = {
    PromptStore?: any;
    router?: any;
}

type ActionLogState = {
    loading: boolean;
    selected: string;
    collapsed: boolean;
}

class ActionLog extends React.Component<ActionLogProps, ActionLogState> {
    state = {
        loading: true,
        collapsed: false,
        selected: null
    }

    constructor(props) {
        super(props);
        // Note: temporarily commenting-out until I complete more refactors related to the LeftNav UX flow
        //this.state.collapsed = !!(getFromLocalstorage(KEYS.leftNavCollapse)) || false;
    }

    componentDidMount(): void {
        // load immediately
        this.loadJobData();

        // also every 60 sec
        window.setInterval(this.loadJobData, 1000 * 60);
    }

    loadJobData = () => {
        const { PromptStore, router } = this.props;

        PromptStore.listJobs()
            .then(() => {
                this.setState({
                    loading: false,
                    // if on JobById page, select the correct job
                    selected: router.params?.jobId
                });
            });
    }

    onItemClick = (id) => {
        window.location.href = `/intention/${id}`;
    }

    onToggleCollapse = () => {
        const { collapsed } = this.state;
        const newState = !collapsed;
        this.setState({ collapsed: newState }, () => saveToLocalstorage(KEYS.leftNavCollapse, newState));
    }

    renderJobItem = (job, index) => {
        const { selected } = this.state;
        return (
            <div
                key={index}
                className={
                    classNames('action-log-item', 'fade-in', {
                        pending: job.type !== 'unsupported' && (job.job_status === 'pending' || job.job_status === 'in_progress'),
                        unsupported: job.type === 'unsupported' || job.error !== null,
                        selected: job.job_id === selected,
                    })
                }
                onClick={() => this.onItemClick(job.trigger_event_id)}
            >
                <div className="action-log-title">
                    <div className="action-log-title-left">
                        {timeAgo.format(new Date(job.updated_timestamp))}
                    </div>
                    <div className="action-log-title-right">
                        {(job.type === 'unsupported' || job.job_status === 'failed') && <AlertCircleIcon />}
                    </div>
                </div>

                <div className='action-log-prompt'>
                    {job.friendly_name}
                </div>
            </div>
        );
    }

    render() {
        const { PromptStore } = this.props;
        const { loading, collapsed } = this.state;
        const actions = [];

        if (loading) {
            actions.push(<div style={{ padding: '20px 0' }} key="action-log-loading"><Progress /></div>)
        } else if (PromptStore.jobs.length > 0) {
            // @ts-ignore
            entries(PromptStore.jobs).forEach(([key, job]: [key: number, job: Job], index: number) => {
                actions.push(this.renderJobItem(job, index));
            });
        }

        if (collapsed) {
            return (
                <div className="action-log collapsed">
                    <div className="action-log-toggle" onClick={this.onToggleCollapse}>
                        <CaretRightIcon />
                    </div>
                </div>
            );
        }

        return (
            <div className="action-log">
                <div className="action-log-container">
                    {actions}
                </div>
            </div>
        );
    }
}

export default withRouter(inject("PromptStore")(observer(ActionLog)));