import React, { useState } from 'react';
import StepHeader from './StepHeader';
import SafeguardsModal from '../modals/SafeguardsModal';
import DataHandlingModal from '../modals/DataHandlingModal';
import { GoogleIcon, CaretRightIcon, GlobeIcon } from 'components/common/Icon';

const Connect = ({ onNextPage }) => {
    const [ safeguardsOpen, setSafeguardsOpen ] = useState(false);
    const [ dataHandlingOpen, setDataHandlingOpen ] = useState(false);

    return (
        <>
            <StepHeader currentStep={2} />

            <div className="adapter-two-col">

                <div className="adapter-two-col--title">
                    <div className="flex-1"></div>

                    <h2>
                        Connect an Account
                    </h2>

                    <p>
                        Adapter lets you retrieve your user data from major platforms so that it can be used to provide you
                        with powerful insights and personalized outcomes. Connecting an account brings your historical data
                        into Adapter and allows Adapter to retrieve new pieces of data when they are created.
                    </p>

                    <p>
                        You get to choose what categories of data you want Adapter to import from an account.
                    </p>

                    <p>
                        <a onClick={() => setSafeguardsOpen(true)}>Learn how Adapter safeguards your data</a>
                    </p>

                    <p>
                        <a onClick={() => setDataHandlingOpen(true)}>Learn what happens to your account data</a>
                    </p>

                    <div className="flex-1"></div>
                </div>

                <div className="adapter-two-col--content">
                    <div className="flex-1"></div>

                    <div className="connect-account-option">
                        <div><GoogleIcon /></div>
                         <div className="flex-1" onClick={onNextPage} style={{ cursor: 'pointer' }}>
                            <p>Google Mail &amp; Calendar</p>
                         </div>
                         <div><CaretRightIcon /></div>
                    </div>

                    <h3 className="connect-account-title">Coming Soon...</h3>

                    <div className="connect-account-option coming-soon">
                        <div><GlobeIcon /></div>
                         <div className="flex-1">
                            <p>Microsoft Outlook</p>
                         </div>
                         {/* <div><p>Request</p></div> */}
                    </div>

                    <div className="connect-account-option coming-soon">
                        <div><GlobeIcon /></div>
                         <div className="flex-1">
                            <p>iCloud</p>
                         </div>
                         {/* <div><p>Request</p></div> */}
                    </div>

                    <div className="connect-account-option coming-soon">
                        <div><GlobeIcon /></div>
                         <div className="flex-1">
                            <p>Facebook</p>
                         </div>
                         {/* <div><p>Request</p></div> */}
                    </div>

                    <div className="connect-account-option coming-soon">
                        <div><GlobeIcon /></div>
                         <div className="flex-1">
                            <p>LinkedIn</p>
                         </div>
                         {/* <div><p>Request</p></div> */}
                    </div>

                    <div className="flex-1"></div>
                </div>
            </div>

            {dataHandlingOpen && <DataHandlingModal onClose={() => setDataHandlingOpen(false)} />}
            {safeguardsOpen && <SafeguardsModal onClose={() => setSafeguardsOpen(false)} />}
        </>
    );
}

export default Connect;