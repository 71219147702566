import React from 'react';
import { inject, observer } from 'mobx-react';

import BasePage from 'components/common/BasePage';
import PromptFlow from './prompt/PromptFlow';
import NLFlow from './prompt/NLFlow';

import './GettingStarted.scss';

type GettingStartedProps = {
    NLPStore?: any;
}

class GettingStarted extends React.Component<GettingStartedProps, {}> {
    render() {
        const { NLPStore } = this.props;

        return (
            <BasePage>
                { NLPStore.isCurrentTab ? <NLFlow /> : <PromptFlow /> }
            </BasePage>
        );
    }
}

export default inject("NLPStore")(observer(GettingStarted));