import React from 'react';
import { inject, observer } from 'mobx-react';
import Pill from 'components/common/Pill';
import Popover from 'components/common/Popover';
import { FlightConstruct } from 'models/Flight';
import { convertTo12Hour, formatDate_ShortHuman } from 'util/Time';

import './OptionsTable.scss';

const getNestedValue = (obj, path) => {
    return path.split('.').reduce((prev, curr) => {
        return prev && prev[curr] !== undefined ? prev[curr] : null;
    }, obj);
};

const formatValue = (label, value) => {
    let displayValue;

    switch (label) {
        case 'departure_date':
        case 'return_date':
            displayValue = formatDate_ShortHuman(value);
            break;

        default:
            console.log(`default format for label: ${label}`);
            displayValue = value;
    }

    return displayValue;
};

const inferredReason = (label) => {
    switch (label) {
        case 'departure_airport':
        case 'destination_airport':
            return 'Location is inferred from Adapter\'s understanding of your home address and/or current location.';

        case 'departure_date':
        case 'return_date':
        case 'return_departure_start_time':
        case 'return_by_time':
            return 'Dates and times are inferred from the text provided by your prompt.';

        case 'arrival_time':
            return 'The "arrive by" time is inferred from your request for flights arriving by a specific time.';
        case 'after_time':
            return 'The "after" time is inferred from your request for flights departing after a specific time.';

        default:
            console.log(`default reason for label: ${label}`);
            return 'Adapter inferred this value, but no information was provided as evidence.';
    }
}

const PillPopover = ({ label, value, inferred = false }) => {
    if (value === null || value === undefined) {
        return <></>;
    }

    if (inferred) {
        return (
            <Popover trigger={<Pill highlighted>{formatValue(label, value)}</Pill>}>
                <>
                    <h2>How did we infer this?</h2>
                    <p>{inferredReason(label)}</p>
                </>
            </Popover>
        );
    }

    return <Pill>{formatValue(label, value)}</Pill>;
}

type OptionsTableProps = {
    PromptStore?: any;
}

class OptionsTable extends React.Component<OptionsTableProps, {}> {
    render() {
        const { PromptStore } = this.props;
        const flightConstruct: FlightConstruct = PromptStore.comprehensionResponse?.payload?.body;
        const inferredFields = PromptStore.comprehensionResponse?.inferred_fields || [];

        if (!flightConstruct) {
            return <></>;
        }

        const outboundDepartureAfterTime = getNestedValue(flightConstruct, "event_constraints.departure_flight.last_event_end_time");
        const outboundArrivebeforeTime = getNestedValue(flightConstruct, "event_constraints.departure_flight.next_event_start_time");
        const returnDepartureAfterTime = getNestedValue(flightConstruct, "event_constraints.return_flight.last_event_end_time");
        const returnArriveByTime = getNestedValue(flightConstruct, "event_constraints.return_flight.next_event_start_time");

        return (
            <div className="adapter-options-table">
                Looking for flights that match your request...

                <p>
                    <span className="category">Type: </span>
                    <PillPopover
                        inferred={inferredFields.includes('trip_type')}
                        value={flightConstruct.trip_type === 'oneway' ? 'One-Way' : 'Round-Trip'}
                        label="trip_type"
                    />

                    <PillPopover
                        inferred={inferredFields.includes('cabin_class')}
                        value={flightConstruct.cabin_class}
                        label="cabin_class"
                    />

                    {/* TODO: why is "stops" returned as a string?  */}
                    <PillPopover
                        inferred={inferredFields.includes('stops')}
                        value={flightConstruct.stops === '0' ? 'Non-Stop' : `${flightConstruct.stops} stop(s)`}
                        label="stops"
                    />
                </p>

                <p>
                    <span className="category">Departure: </span>
                    <PillPopover
                        inferred={inferredFields.includes('departure_airport')}
                        value={flightConstruct.departure_airport && flightConstruct.departure_airport[0]}
                        label="departure_airport"
                    />
                    <PillPopover
                        inferred={inferredFields.includes('departure_date')}
                        value={flightConstruct.departure_date}
                        label="departure_date"
                    />
                    {outboundDepartureAfterTime && (
                        <PillPopover
                            inferred={true}
                            value={`Departing after ${convertTo12Hour(outboundDepartureAfterTime.split(" ")[1])}`}
                            label="after_time"
                        />
                    )}
                    {outboundArrivebeforeTime && (
                        <PillPopover
                            inferred={true}
                            value={`Event consideration time ${convertTo12Hour(outboundArrivebeforeTime.split(" ")[1])}`}
                            label="after_time"
                        />
                    )}
                </p>

                <p>
                    <span className="category">Arrival: </span>
                    <PillPopover
                        inferred={inferredFields.includes('destination_airport')}
                        value={flightConstruct.destination_airport && flightConstruct.destination_airport[0]}
                        label="destination_airport"
                    />
                    {flightConstruct.arrival_time !== '23:59' && (
                        <PillPopover
                            inferred={inferredFields.includes('arrival_time')}
                            value={`Arriving by ${convertTo12Hour(flightConstruct.arrival_time)}`}
                            label="arrival_time"
                        />
                    )}
                    {/* <Pill highlighted={false}>{flightConstruct.next_location}</Pill>  */}
                </p>

                {flightConstruct.trip_type !== 'oneway' && (
                    <p>
                        <span className="category">Return: </span>
                        <PillPopover
                            inferred={inferredFields.includes('return_date')}
                            value={flightConstruct.return_date}
                            label="return_date"
                        />
                        {returnDepartureAfterTime && (
                            <PillPopover
                                inferred={true}
                                value={`Departing after ${convertTo12Hour(returnDepartureAfterTime.split(" ")[1])}`}
                                label="after_time"
                            />
                        )}

                        {returnArriveByTime && (
                            <PillPopover
                                inferred={true}
                                value={`Arrive by ${convertTo12Hour(returnArriveByTime.split(" ")[1])}`}
                                label="after_time"
                            />
                        )}
                    </p>
                )}

                <p>
                    <span className="category">Passengers: </span>
                    <PillPopover
                        inferred={inferredFields.includes('passengers')}
                        value={flightConstruct.passengers}
                        label="passengers"
                    />
                </p>
            </div>
        );
    }
}

export default inject("PromptStore")(observer(OptionsTable));