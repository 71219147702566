import React, { ChangeEvent, FormEvent } from 'react';
import { inject, observer } from "mobx-react";
import { toast } from "react-toastify";

import StepHeader from './StepHeader';
import Card from 'components/common/Card';
import BaseForm from 'components/forms/BaseForm';

type UserKnowledgeProps = {
    onNextPage: () => void;
    UserStore?: any;
};

type UserKnowledgeState = {
    homeAddress: string | undefined;
    officeAddress: string | undefined;
    preferredHomeAirport: string | undefined;
    preferredAirline: string | undefined;
    favoriteCuisines: string | undefined;
}

class UserKnowledge extends React.Component<UserKnowledgeProps, UserKnowledgeState> {
    state = {
        homeAddress: '',
        officeAddress: '',
        preferredHomeAirport: '',
        preferredAirline: '',
        favoriteCuisines: '',
    };

    componentDidMount(): void {
        const { UserStore } = this.props;
        UserStore
            .loadUser()
            .then((user) => {
                this.setState({
                    homeAddress: user?.metadata?.homeAddress || '',
                    officeAddress: user?.metadata?.officeAddress || '',
                    preferredHomeAirport: user?.metadata?.preferredHomeAirport || '',
                    preferredAirline: user?.metadata?.preferredAirline || '',
                    favoriteCuisines: user?.metadata?.favoriteCuisines || '',
                });
            })
    }

    onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        // @ts-ignore
        this.setState({ [name]: value });
    };

    onSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
        const { UserStore, onNextPage } = this.props;
        const { homeAddress, officeAddress, preferredHomeAirport, preferredAirline, favoriteCuisines } = this.state;

        event.preventDefault();

        UserStore.updateUserMetadata({
            homeAddress, 
            officeAddress, 
            preferredHomeAirport, 
            preferredAirline, 
            favoriteCuisines
        })
            .then(onNextPage)
            .catch(() => {
                toast.error("Oops! Something went wrong, please try again.", { icon: false });
            });
    };

    render() {
        const { UserStore } = this.props;
        const { homeAddress, officeAddress, preferredHomeAirport, preferredAirline, favoriteCuisines } = this.state;

        const config = [
            {
                labelText: "Home Address",
                fieldId: "homeAddress",
                type: "text",
                value: homeAddress,
                required: false,
            },
            {
                labelText: "Office Address",
                fieldId: "officeAddress",
                type: "text",
                value: officeAddress,
                required: false,
            },
            {
                labelText: "Preferred Home Airport",
                fieldId: "preferredHomeAirport",
                type: "text",
                value: preferredHomeAirport,
                required: false,
                placeholder: "e.g. ORD (Chicago O'Hare)"
            },
            {
                labelText: "Preferred Airline",
                fieldId: "preferredAirline",
                type: "text",
                value: preferredAirline,
                required: false,
                placeholder: "e.g. Southwest"
            },
            {
                labelText: "Favorite Cuisines",
                fieldId: "favoriteCuisines",
                type: "text",
                value: favoriteCuisines,
                required: false,
            }
        ];

        return (
            <>
                <StepHeader currentStep={6} />

                <div className="adapter-two-col">

                    <div className="adapter-two-col--title">
                        <div className="flex-1"></div>

                        <h2>
                            Preferences
                        </h2>

                        <p>
                            The following details will help Adapter provide more relevant and accurate results.
                        </p>

                        <p>
                            Adapter is already trying to infer some of these values from your account!
                        </p>

                        <div className="flex-1"></div>
                    </div>

                    <div className="adapter-two-col--content">
                        <div className="flex-1"></div>

                        <Card>
                            <BaseForm
                                config={config}
                                isLoading={UserStore.isLoading}
                                buttonText="Complete Onboarding!"
                                onChange={this.onChangeHandler}
                                onSubmit={this.onSubmitHandler}
                                style={{ minWidth: '50%' }}
                            />
                        </Card>

                        <div className="flex-1"></div>
                    </div>
                </div>
            </>
        );
    }
}

export default inject("UserStore")(observer(UserKnowledge));