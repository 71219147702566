import React, { FormEvent, ChangeEvent } from "react";
import { inject, observer } from "mobx-react";

import { Account } from './ConnectedAccounts';
import { GoogleIcon, TrashIcon } from 'components/common/Icon';
import BaseForm from "components/forms/BaseForm";
import DisconnectAccountModal from "./DisconnectAccountModal";

import { gmail_options, time_options } from "util/GoogleOAuth";
import { ALL_TIME } from "constants/Scopes";

import './AccountDisplay.scss';

const defaultGmailCategoryState = Array.from(gmail_options);

interface AccountDisplayProps {
    hideIcon?: boolean;
    account?: Account;
    AuthStore?: any;
    UserStore?: any;
    OnboardingStore?: any;
    buttonText?: string;

    beforeSubmit?: ( fn: () => void ) => void;
}

class AccountDisplay extends React.Component<AccountDisplayProps, any> {
    state = {
        gmailCategoryState: defaultGmailCategoryState,
        gmailTimeState: time_options[0],
        accountEmail: undefined,
        hasChanged: false,
        showDisconnect: false
    };

    constructor(props: AccountDisplayProps) {
        super(props);

        const { account } = props;

        if (account) {
            const { timeRange, labels } = account.configuration;

            this.state = {
                gmailCategoryState: labels.map(v => ({ label: v, value: v })),

                // @ts-ignore
                gmailTimeState: time_options.find(e => e.label === `${timeRange.value} ${timeRange.unit.charAt(0).toUpperCase() + timeRange.unit.slice(1)}`) || { label: ALL_TIME, value: ALL_TIME },

                // @ts-ignore
                accountEmail: account.accountEmail,

                hasChanged: false,
                showDisconnect: false
            };
        }
    }

    onAuthorizeHandler = (event: FormEvent<HTMLFormElement>) => {
        const { OnboardingStore, beforeSubmit } = this.props;
        const { gmailCategoryState, gmailTimeState, accountEmail } = this.state;

        event.preventDefault();

        const openGoogleFlow = () => {
            OnboardingStore.handleGoogleAuth(
                gmailCategoryState.map((option: any) => option.value),
                gmailTimeState.value,
                accountEmail
            );
        }

        if (beforeSubmit) {
            beforeSubmit(openGoogleFlow);
        } else {
            openGoogleFlow();
        }
    };

    onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        // @ts-ignore
        this.setState({
            [name]: value,
            hasChanged: true
        });
    };

    onSelectChangeHandler = (selectedOption: { label: string, value: string }, field: { name: string }) => {
        this.setState({
            [field.name]: selectedOption,
            hasChanged: true
        });
    }

    isEmailAddressConnected = (account: Account) => {
        return account?.connected ? true : false;
    }

    emailAddressValid = (accountEmail: string) => {
        return accountEmail !== undefined && accountEmail?.length === 0 ? "Email address is required" : undefined
    }

    onDisconnectCancel = () => this.setState({ showDisconnect: false });

    onDisconnectSubmit = () => {
        const { AuthStore, account } = this.props;

        AuthStore.disconnectAccount(account.accountEmail, account.accountVendor)
            .then(() => window.location.reload());
    }

    onDisconnect = () => this.setState({ showDisconnect: true });

    render() {
        const { account, hideIcon = false, buttonText = 'Connect' } = this.props;
        const { gmailCategoryState, gmailTimeState, accountEmail, hasChanged, showDisconnect } = this.state;

        const config: any[] = [
            {
                labelText: "Email categories",
                fieldId: "gmailCategoryState",
                type: "select",
                selectConfig: {
                    defaultValue: gmailCategoryState,
                    options: gmail_options,
                    isSearchable: false,
                    isMulti: true,
                    onChange: this.onSelectChangeHandler
                },
                //value: gmail_categories,
                required: true,
                errorText: gmailCategoryState.length === 0 ? "At least one Gmail category is required" : undefined
            },
            {
                labelText: "Account history",
                fieldId: "gmailTimeState",
                type: "select",
                selectConfig: {
                    defaultValue: gmailTimeState,
                    options: time_options,
                    isSearchable: false,
                    onChange: this.onSelectChangeHandler
                },
                //value: gmailTimeState,
                required: true,
                errorText: !gmailTimeState?.value ? "You must select a time range" : undefined
            }
        ];

        // only show the email field if there is an account already
        if (account) {
            config.unshift({
                labelText: "Email address",
                fieldId: "accountEmail",
                type: "email",
                value: accountEmail,
                required: true,
                disabled: !!account,
                // @ts-ignore
                errorText: this.emailAddressValid(accountEmail)
            });
        }

        return (
            <div className="adapter-account-display">
                {account && (
                    <div className="disconnect">
                        <TrashIcon
                            onClick={this.onDisconnect}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                )}

                {!hideIcon && (
                    <div className="account-icon">
                        <GoogleIcon title={account?.accountVendor.toUpperCase()} />
                    </div>
                )}

                <BaseForm
                    config={config}
                    isLoading={false}
                    isDisabled={account ? this.isEmailAddressConnected(account) && !hasChanged : false}
                    buttonText={
                        account && !this.isEmailAddressConnected(account)
                            ? 'Reconnect account'
                            : buttonText
                    }
                    buttonStyle={
                        account && !this.isEmailAddressConnected(account)
                            ? { backgroundColor: '#FAA7E0', borderColor: '#FAA7E0' }
                            : undefined
                    }
                    onChange={this.onChangeHandler}
                    onSubmit={this.onAuthorizeHandler}
                    style={{ minWidth: '50%' }}
                />

                {showDisconnect && (
                    <DisconnectAccountModal
                        onCancel={this.onDisconnectCancel}
                        onDisconnect={this.onDisconnectSubmit}
                    />
                )}
            </div>
        );
    }
}

export default inject("AuthStore", "UserStore", "OnboardingStore")(observer(AccountDisplay));