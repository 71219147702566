const config = {
    auth0: {
      domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
      redirectUri: `${window.location.origin}${process.env.REACT_APP_REDIRECT_URI || ""}`,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
    },
  };
  
  export default config;
  