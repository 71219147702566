import React from "react";
import { inject, observer } from "mobx-react";

import AuthenticatedPage from "components/common/AuthenticatedPage";
import Footer from "components/common/Footer";

import Welcome from './steps/Welcome';
import Trust from './steps/Trust';
import Connect from './steps/Connect';
import AccountDetails from "./steps/AccountDetails";
import AccountIngest from "./steps/AccountIngest";
import UserKnowledge from "./steps/UserKnowledge";
import DataControls from "./steps/DataControls";
import Preferences from "./steps/Preferences";

import "../../styles/index.scss";
import "./Onboarding.scss";

interface OnboardingPageProps {
    OnboardingStore?: any;
}

class OnboardingPage extends React.Component<OnboardingPageProps, {}> {
    renderPage = () => {
        const { OnboardingStore } = this.props;

        switch (OnboardingStore.currentPage) {
            case 0:
                return <Welcome onNextPage={() => OnboardingStore.setPage(1)} />;

            case 1:
                return <Trust onNextPage={() => OnboardingStore.setPage(2)} />;

            case 2:
                return <Connect onNextPage={() => OnboardingStore.setPage(3)} />;

            case 3:
                return <DataControls onNextPage={() => OnboardingStore.setPage(4)} />;

            case 4:
                return <AccountIngest onNextPage={() => OnboardingStore.setPage(5)} />;

            case 5:
                return <AccountDetails onNextPage={() => OnboardingStore.setPage(6)} />;

            case 6:
                return <UserKnowledge onNextPage={() => OnboardingStore.completeOnboarding()} />;

            // case 6:
            //     return <Preferences />;

            default:
                console.error('Fell into default case, check <Onboarding /> page!')
                return <></>;
        }
    }

    render() {
        return (
            <AuthenticatedPage>
                <div className="adapter-basepage">
                    <div className="adapter-basepage-inner">
                        <div className="adapter-fullscreen-content">
                            {this.renderPage()}

                            <Footer style={{ right: 32 }} />
                        </div>
                    </div>
                </div>
            </AuthenticatedPage>
        );
    }
}

export default inject("OnboardingStore")(observer(OnboardingPage));
