import React from 'react';
import L from "leaflet";

import Button from 'components/common/Button';
import Pill from 'components/common/Pill';

import './HotelOption.scss';

const MAP_LOCATION_COLOR = '#EF6820';

const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3
});

type HotelResult = {
    address: string;
    name: string;
    description: string;
    amenities: string[];
    booking_url: string;
    check_in_time: string;
    check_out_time: string;
    class: string;
    price: number;

    //     gps_coordinates: {
    //         lat: number;
    //         lng: number;
    //     }
    // }
}

type HotelOptionProps = {
    option: HotelResult;
    isTopPick: boolean;
    allOptions: HotelResult[];
};

class HotelOption extends React.Component<HotelOptionProps, {}> {
    private mapRef = null;

    state = {
        map: null
    }

    constructor(props) {
        super(props);

        this.state = {
            map: null
        };

        this.mapRef = React.createRef();
    }

    componentDidMount(): void {
        this.drawMap();
    }

    drawMap = () => {
        const { option } = this.props;
        const hotelDetails = option;
        // const { lat, lng } = hotelDetails.gps_coordinates;

        // // Check if the map has already been initialized
        // if (!this.state.map) {
        //     let zoomScale = 16;

        //     // Initialize the map
        //     const initializedMap = L.map(this.mapRef.current, {
        //         zoomControl: false,
        //         attributionControl: false,
        //     }).setView([lat, lng], zoomScale);

        //     const tileLayer = L.tileLayer(
        //         "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        //     ).addTo(initializedMap);

        //     tileLayer.getContainer().style.filter = "grayscale(100%)";

        //     L.svg({ clickable: true }).addTo(initializedMap);

        //     L.circleMarker([lat, lng], {
        //         color: MAP_LOCATION_COLOR,
        //         fillColor: MAP_LOCATION_COLOR,
        //         fillOpacity: 0.5,
        //         radius: 4 // Fixed pixel radius
        //     }).addTo(initializedMap);

        //     // Store the map object in state to avoid reinitialization
        //     this.setState({ map: initializedMap });
        // }
    };

    render() {
        const { option, isTopPick, allOptions } = this.props;
        const hotelDetails = option;

        return (
            <div className="adapter-intention-option">
                <div className="option-price-header">
                    <div className="option-price-header-wrapper">
                        <h3>{USDollar.format(hotelDetails.price)}</h3>
                        <p>{hotelDetails.name}</p>
                    </div>
                    {isTopPick && <Pill highlighted>Best Match</Pill>}
                </div>

                <div className="option-details">
                    <ul>
                        <li>{hotelDetails.class}</li>
                        <li>Check-In: {hotelDetails.check_in_time}</li>
                        <li>Check-Out: {hotelDetails.check_out_time}</li>
                    </ul>

                    <p>{hotelDetails.description}</p>

                    <p>{hotelDetails.address}</p>

                    {/* <div className="option-details-body-wrapper">
                        <div ref={this.mapRef} style={{ height: '300px', flex: 1 }}></div>
                    </div> */}
                </div>


                {option.booking_url && (
                    <Button
                        size="2xl"
                        onClick={() => window.open(option.booking_url, '_blank')}
                        style={{ width: '100%', marginTop: '24px' }}
                    >Book Now</Button>
                )}
            </div>
        );
    }
}

export default HotelOption;