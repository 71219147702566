import React from 'react';
import { inject, observer } from 'mobx-react';
import withRouter from 'hooks/withRouter';

import './PromptToggle.scss';

type PromptToggleProps = {
    NLPStore?: any;
    PromptStore?: any;
    router: any;
};

class PromptToggle extends React.Component<PromptToggleProps, {}> {
    handleToggleClick = (isNLP: boolean = false) => {
        const { NLPStore, PromptStore, router } = this.props;
        NLPStore.setCurrentTab(isNLP);

        if (isNLP) {
            NLPStore.setRawPrompt(null);
            NLPStore.clearResponse();
        } else {
            PromptStore.setRawPrompt(null);
            PromptStore.clearComprehensionResponse();
        }

        router.navigate('/dashboard');
    }

    render() {
        const { NLPStore } = this.props;
        return (
            <div className="toggle-switch-container">
                <div
                    className={`toggle-switch ${!NLPStore.isCurrentTab ? 'active' : ''}`}
                    onClick={() => this.handleToggleClick(false)}
                >
                    <span>Start a Task</span>
                </div>
                <div
                    className={`toggle-switch ${NLPStore.isCurrentTab ? 'active' : ''}`}
                    onClick={() => this.handleToggleClick(true)}
                >
                    <span>Ask a Question</span>
                </div>
            </div>
        );
    }
}

export default withRouter(inject("NLPStore", "PromptStore")(observer(PromptToggle)));