import { makeObservable, action, observable } from "mobx";
import { toast } from "react-toastify";

import API from "util/API";
import { PromptSuggestion } from "components/common/Intention";
import { Job } from "models/Job";
import { ComprehensionResponse, Operation, Target } from "models/comprehension";

const REASON = 'Request aborted';

class PromptStore {
  // @ts-ignore
  public currentJob: Job = undefined;
  public jobs: Job[] = [];
  public promptSuggestions: PromptSuggestion[] = [];

  public loadingGetJob: boolean = false;
  public loadingParseInput: boolean = false;
  public loadingSuggestions: boolean = false;

  public comprehensionResponse: ComprehensionResponse = null;
  public rawPrompt: string = null;

  public parseRequestController: AbortController = null;

  constructor() {
    makeObservable(this, {
      promptSuggestions: observable,
      jobs: observable,
      currentJob: observable,
      comprehensionResponse: observable,
      rawPrompt: observable,

      loadingGetJob: observable,
      loadingParseInput: observable,
      loadingSuggestions: observable,

      setRawPrompt: action,
      setCurrentJob: action,
      clearComprehensionResponse: action,
      setComprehensionResponseFromJob: action,

      listJobs: action,
      listJobsSuccess: action,
      listJobsFailure: action,

      getJob: action,
      getJobSuccess: action,
      getJobFailure: action,

      parseInput: action,
      parseInputSuccess: action,
      parseInputFailure: action,

      getPromptSuggestions: action,
      getPromptSuggestionsSuccess: action,
      getPromptSuggestionsFailure: action,
    });
  }

  listJobsSuccess = ({ jobs }) => {
    this.jobs = jobs;
  };

  listJobsFailure = () => { };

  listJobs = () => {
    return new Promise((resolve, reject) => {
      API.get("/api/prompt/jobs")
        .then(response => response.json())
        .then(data => {
          this.listJobsSuccess(data);
          return resolve(data);
        })
        .catch((error) => {
          this.listJobsFailure();
          return reject(error);
        });
    });
  };

  setCurrentJob = (data: any) => {
    // @ts-ignore
    this.currentJob = data;
  };

  getJobSuccess = (data: any) => {
    this.loadingGetJob = false;
    this.setCurrentJob(data);
    return data;
  };

  getJobFailure = () => {
    this.loadingGetJob = false;

    toast.error(
      "An internal error occurred while processing your request. Please try again in a few minutes.",
      { theme: 'dark', position: 'bottom-right' }
    );
  };

  getJob = (jobId: string) => {
    // @ts-ignore
    this.loadingGetJob = true;

    return new Promise((resolve, reject) => {
      API.get(`/api/prompt/jobs/${jobId}`)
        .then(response => response.json())
        .then(data => {
          this.getJobSuccess(data);
          return resolve(data);
        })
        .catch((error) => {
          this.getJobFailure();
          return reject(error);
        });
    });
  };

  clearComprehensionResponse = () => {
    this.comprehensionResponse = null;

    if (this.parseRequestController) {
      this.parseRequestController.abort(REASON);
      this.parseRequestController = null;
    }
  };

  setComprehensionResponseFromJob = (job: Job) => {
    const { job_status, type, friendly_name, error, request_payload } = job;

    // must be comp_v1
    if (request_payload?.inferred_fields) {
      this.comprehensionResponse = {
        // @ts-ignore - this is supporting legacy
        prompt_category: type,
        payload: request_payload,
        inferred_fields: request_payload.inferred_fields,
      };
    } else if (!request_payload) {
      this.comprehensionResponse = {
        // @ts-ignore - this is supporting legacy error handlers
        prompt_category: 'other',
      };
    }
    // must be comp_v2
    else {
      const { metadata, body } = request_payload;
      
      this.comprehensionResponse = new ComprehensionResponse({
        operation: metadata.operation[0] as Operation,
        target: metadata.operation[1] as Target,
        constraints: metadata.constraints,
        summary: body
      });
    }
  };

  parseInputSuccess = (data: any) => {
    this.comprehensionResponse = new ComprehensionResponse(data);
    this.loadingParseInput = false;
    this.parseRequestController = null;
  };

  parseInputFailure = (reason) => {
    this.loadingParseInput = false;
    this.parseRequestController = null;

    if (reason === REASON) {
      return;
    }

    toast.error(
      "An internal error occurred while processing your request. Please try again in a few minutes.",
      { theme: 'dark', position: 'bottom-right' }
    );
  };

  parseInput = () => {
    this.loadingParseInput = true;

    if (this.parseRequestController) {
      this.parseRequestController.abort(REASON);
    }

    this.parseRequestController = new AbortController();

    return new Promise((resolve, reject) => {
      API.post("/api/prompt/", { prompt: this.rawPrompt }, false, this.parseRequestController.signal)
        .then(response => response.json())
        .then(data => {
          this.parseInputSuccess(data);
          return resolve(data);
        })
        .catch((error) => {
          this.parseInputFailure(error);
          return error !== REASON && reject(error);
        });
    });
  };

  setRawPrompt = (prompt: string) => {
    this.rawPrompt = prompt;

    if (this.parseRequestController) {
      this.parseRequestController.abort(REASON);
      this.parseRequestController = null;
    }

    if (prompt !== '' && prompt !== null) {
      // @ts-ignore
      document.querySelector('.intention-inputbar textarea')?.focus();
    }
  }

  getPromptSuggestionsSuccess = (data: any) => {
    this.loadingSuggestions = false;
    this.promptSuggestions = data;
    return data;
  };

  getPromptSuggestionsFailure = () => {
    this.loadingSuggestions = false;
  };

  getPromptSuggestions = (jobId: string) => {
    // @ts-ignore
    this.loadingSuggestions = true;

    return new Promise((resolve, reject) => {
      API.get('/api/prompt/suggestions')
        .then(response => response.json())
        .then(data => {
          this.getPromptSuggestionsSuccess(data);
          return resolve(data);
        })
        .catch((error) => {
          this.getPromptSuggestionsFailure();
          return reject(error);
        });
    });
  };
}

const store = new PromptStore();

export default store;