import React from "react";

export default class Home extends React.Component {
  componentDidMount() {
    window.location.href = "/login";
  }

  render() {
    return (<></>);
  }
}
