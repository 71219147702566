import React from 'react';
import { inject, observer } from 'mobx-react';

import Card from 'components/common/Card';
import Button from 'components/common/Button';
import NLPInputBar from 'pages/intentions/common/NLPInputBar';
import AdapterChat from 'components/AdapterChat';
import PromptToggle from './PromptToggle';
import NLOptionsTable from 'pages/intentions/common/NLOptionsTable';
import UnsupportedOption from 'pages/intentions/options/UnsupportedOption';
import IntentionList from 'components/common/Intention/IntentionList';

import './NLFlow.scss';

type NLFlowProps = {
    NLPStore?: any;
}

class NLFlow extends React.Component<NLFlowProps> {
    renderIntroContent = () => {
        return (
            <>
                <p className="introduction">
                    Use Adapter to understand your digital habits and preferences.
                </p>
            </>
        );
    }

    handleButtonClickWrongResults = () => {
        const { NLPStore } = this.props;
        NLPStore.giveFeedback("UserFeedbackWrongResults");
    }

    handleButtonClickIncorrectQueryParsing = () => {
        const { NLPStore } = this.props;
        NLPStore.giveFeedback("UserFeedbackIncorrectQueryParsing");
    }

    render() {
        const { NLPStore } = this.props;
        const { submittedParsing, submittedWrong } = NLPStore;

        const hasPrompted = NLPStore.loading || NLPStore.response;
        const messages = [];

        if (hasPrompted) {
            messages.push({
                name: 'You',
                time: Date.now(),
                loading: false,
                content: (
                    <Card>
                        <p>{NLPStore.rawPrompt}</p>
                    </Card>
                )
            });

            if (NLPStore.response) {
                messages.push({
                    name: 'Adapter',
                    time: Date.now(),
                    loading: false,
                    content: (<NLOptionsTable />)
                });
    
                if (NLPStore.response?.mappedQuery?.category !== 'not_query') {
                    messages.push({
                        name: 'Adapter',
                        time: Date.now(),
                        loading: false,
                        content: (
                            <>
                                {NLPStore.response?.responseString}

                                <div className="nl-feedback">
                                    <Button
                                        disabled={submittedWrong}
                                        onClick={this.handleButtonClickWrongResults}
                                    >Wrong Results</Button>
                                    
                                    <Button 
                                        disabled={submittedParsing}
                                        onClick={this.handleButtonClickIncorrectQueryParsing}
                                    >Incorrect query parsing</Button>
                                </div>
                            </>
                        )
                    });
                } else {
                    messages.push({
                        name: 'Adapter',
                        time: Date.now(),
                        loading: false,
                        content: (<UnsupportedOption />)
                    });
                }
            } else {
                messages.push({
                    name: 'Adapter',
                    time: Date.now(),
                    loading: true,
                    content: (<></>)
                });
            }
        }

        return (
            <>
                {!hasPrompted && (
                    <div className="adapter-fullscreen-content">
                        <div className="flex-1"></div>

                        <PromptToggle />
                        
                        <IntentionList />
                        
                        <NLPInputBar placeholder="Tell me about my data..." />
                        
                        <div className="flex-1"></div>
                    </div>
                )}
                
                {hasPrompted && (
                    <AdapterChat messages={messages} />
                )}
            </>
        );
    }
}

export default inject("NLPStore")(observer(NLFlow));