import React from "react";
import { observer, inject } from "mobx-react";

const AuthenticatedPage = ({ children, AuthStore }: any) => {
  if (!AuthStore.isAuthenticated) {
    window.location.href = "/login";
  }

  return (<>{AuthStore.isAuthenticated ? children : []}</>);
};

export default inject('AuthStore')(observer(AuthenticatedPage));
