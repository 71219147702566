import React from 'react';
import { inject, observer } from 'mobx-react';

import { formatDate_MMDDYYY } from 'util/Time';
import Pill from 'components/common/Pill';
import Popover from 'components/common/Popover';

import './OptionsTable.scss';

type Constraint = {
    from_date?: string;
    to_date?: string;
    departure_airport?: string;
    arrival_airport?: string;
    airline_iata_code?: string;
};

type Aspect = 'price'
    | 'departure_airport'
    | 'arrival_airport'
    | 'departure_time_of_day'
    | 'arrival_time_of_day'
    | 'departure_datetime'
    | 'checkin_datetime'
    | 'checkout_datetime'
    | 'airline_iata_code'
    | 'seat_preference';

type Aggregation = 'median'
    | 'average'
    | 'max'
    | 'min'
    | 'top-5'
    | 'bottom-5'
    | 'top-5-price'
    | 'bottom-5-price'
    | 'total-count'
    | 'total-sum'
    | 'frequency';

type NLResponseQuery = {
    aggregation: Aggregation;
    aggregation_aspects: string[];
    aspects: Aspect[];
    category: 'flights' | 'hotels' | 'purchases' | 'other' | 'not_query';
    constraints: Constraint;
};

const formatHyphenatedText = (text: string = '') => text
    .replace(/\_/g, ' ')
    .replace(/\-/g, ' ')
    .toLowerCase()
    .replace(/\b[a-z]/g, s => s.toUpperCase());

const formatValue = (label, value) => {
    let displayValue;

    switch (label) {
        case 'to_date':
            displayValue = 'To: ' + formatDate_MMDDYYY(value);
            break;

        case 'from_date':
            displayValue = 'From: ' + formatDate_MMDDYYY(value);
            break;
        
        case 'departure_datetime':
            displayValue = 'Departing: ' + formatDate_MMDDYYY(value);
            break;

        case 'departure_time_of_day':
            displayValue = 'Departing: ' + value;
            break;

        case 'arrival_time_of_day':
            displayValue = 'Arriving: ' + value;
            break;

        case 'airline_iata_code':
            displayValue = 'Airline: ' + value;
            break;

        case 'arrival_airport':
            displayValue = 'Arriving: ' + value;
            break;

        case 'departure_airport':
            displayValue = 'Departing: ' + value;
            break;

        default:
            console.log(`default format for label: ${label}`, value);
            displayValue = formatHyphenatedText(value);
    }

    return displayValue;
};

const inferredReason = (label) => {
    switch (label) {
        default:
            console.log(`default reason for label: ${label}`);
            return 'Adapter inferred this value, but no information was provided as evidence.';
    }
}

const PillPopover = ({ label, value, inferred = false }) => {
    if (value === null || value === undefined) {
        return <></>;
    }

    if (inferred) {
        return (
            <Popover trigger={<Pill highlighted>{formatValue(label, value)}</Pill>}>
                <>
                    <h2>How did we infer this?</h2>
                    <p>{inferredReason(label)}</p>
                </>
            </Popover>
        );
    }

    return <Pill>{formatValue(label, value)}</Pill>;
}

type NLOptionsTableProps = {
    NLPStore?: any;
}

class NLOptionsTable extends React.Component<NLOptionsTableProps, {}> {
    render() {
        const { NLPStore } = this.props;
        const mappedQuery: NLResponseQuery = NLPStore.response?.mappedQuery;

        if (!mappedQuery) {
            return <></>;
        }

        const features = mappedQuery?.aspects?.map((name, i) => {
            return <PillPopover key={i} value={name.toUpperCase()} label="aspects" />
        });

        const aggregations = mappedQuery?.aggregation_aspects?.map((name, i) => {
            return <PillPopover key={i} value={name.toUpperCase()} label="aggregation_aspects" />
        });

        const filters = [];
        for (const [key, value] of Object.entries(mappedQuery?.constraints || {})) {
            filters.push(
                <PillPopover key={key} value={value} label={key} />
            );
        }

        return (
            <div className="adapter-options-table">
                Looking for information in your data that matches your request...

                <p>
                    <span className="category">Category: </span>
                    <PillPopover
                        value={mappedQuery?.category.toUpperCase()}
                        label="category"
                    />
                </p>

                {features.length > 0 && (
                    <p>
                        <span className="category">Features: </span>
                        {features}
                    </p>
                )}

                {filters.length > 0 && (
                    <p>
                        <span className="category">Filters: </span>
                        {filters}
                    </p>
                )}

                {mappedQuery?.aggregation && (
                    <p>
                        <span className="category">Calculation: </span>
                        <PillPopover
                            value={mappedQuery?.aggregation.toUpperCase()}
                            label="aggregation"
                        />
                        {aggregations}
                    </p>
                )}
            </div>
        );
    }
}

export default inject("NLPStore")(observer(NLOptionsTable));