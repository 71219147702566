import React, { ChangeEvent, FormEvent } from "react";

import Input, { InputProps } from "../fields/Input";
import Password from "../fields/Password";
import Checkbox from "../fields/Checkbox";
import Button from "../../common/Button";

import './BaseForm.scss';

export interface Config extends Omit<InputProps, 'onChange'> {
  checked?: boolean;
  selectConfig?: any;
}

interface Props {
  buttonStyle?: any;
  buttonText: string;
  config: Config[];
  isLoading: boolean;
  isDisabled?: boolean;
  onChange: (event: ChangeEvent<any>) => void;
  onSubmit: (event: FormEvent<any>) => void;
  style?: any;
  cls?: string;
}

export default function BaseForm({
  config,
  isLoading,
  buttonText,
  buttonStyle,
  onChange,
  onSubmit,
  cls = '',
  style = {},
  isDisabled = false
}: Props) {
  const hasError = config.some((c) => c.errorText);

  return (
    <form className={`adapter-baseform ${cls}`} onSubmit={onSubmit} style={style}>
      {config.map((input: Config) => {
        if (input.type === 'checkbox') {
          return (
            <Checkbox
              key={input.fieldId}
              {...input}
              onChange={onChange}
            />
          );
        }

        if (input.type === 'password') {
          return (
            <Password
              key={input.fieldId}
              {...input}
              onChange={onChange}
            />
          );
        }

        return (
          <Input
            key={input.fieldId}
            {...input}
            onChange={onChange}
          />
        );
      })}

      <Button
        type="submit" 
        disabled={isLoading || isDisabled || hasError}
        style={buttonStyle}
      >{buttonText}</Button>
    </form>
  );
}
