import React from "react";
import { inject, observer } from "mobx-react";

import { withAuth0, WithAuth0Props } from "@auth0/auth0-react";

import "../../styles/index.scss";

interface SignInPageBaseProps {
  AuthStore?: any;
}

interface SignInPageProps extends WithAuth0Props, SignInPageBaseProps {};

class SignInPage extends React.Component<SignInPageProps, {}> {

  componentDidMount(): void {
    const { AuthStore } = this.props;
    const { loginWithRedirect } = this.props.auth0;

    if (AuthStore.isAuthenticated) {
      window.location.href = "/dashboard";
    }
    loginWithRedirect();
  }

  render() {
    return null;
  }
}

export default inject("AuthStore")(observer(withAuth0(SignInPage)));
