import React from 'react';
import { inject, observer } from 'mobx-react';

import Card from 'components/common/Card';
import InputBar from 'pages/intentions/common/InputBar';
import AdapterChat from 'components/AdapterChat';
import PromptToggle from './PromptToggle';
import IntentionList from 'components/common/Intention/IntentionList';
import UnsupportedOption from 'pages/intentions/options/UnsupportedOption';

type PromptFlowProps = {
    PromptStore?: any;
    NLPStore?: any;
}

class PromptFlow extends React.Component<PromptFlowProps, {}> {
    renderIntroContent = () => {
        return (
            <>
                <p className="introduction">
                    Use Adapter to book flights or learn about your flying habits, just enter your request in plain language using the input bar below.
                </p>
            </>
        );
    }

    render() {
        const { PromptStore } = this.props;

        const hasPrompted = PromptStore.loadingParseInput || PromptStore.comprehensionResponse;
        const messages = [];

        if (hasPrompted) {
            messages.push({
                name: 'You',
                time: Date.now(),
                loading: false,
                content: (
                    <Card>
                        <p>{PromptStore.rawPrompt}</p>
                    </Card>
                )
            });

            if (PromptStore.loadingParseInput) {
                messages.push({
                    name: 'Adapter',
                    time: Date.now(),
                    loading: hasPrompted,
                    content: (<></>)
                });
            } 
            // if the response is a Memory, we stay in this component
            // otherwise <InputBar /> redirects the screen to <JobById /> and we never get back here
            else if (PromptStore.comprehensionResponse?.target === 'memory') {
                messages.push({
                    name: 'Adapter',
                    time: Date.now(),
                    loading: false,
                    content: (
                        <Card>
                            <p>{PromptStore.comprehensionResponse.render().text}</p>
                        </Card>
                    )
                });
            } 
            // comp_v1 errors might come back so we can display them as <UnsupportedOption />
            else if (PromptStore.comprehensionResponse?.operation === undefined) {
                messages.push({
                    name: 'Adapter',
                    time: Date.now(),
                    loading: false,
                    content: (
                        <UnsupportedOption />
                    )
                });
            }
        }

        return (
            <>
                {!hasPrompted && (
                    <div className="adapter-fullscreen-content">
                        <div className="flex-1"></div>

                        <PromptToggle />
                        
                        <IntentionList />
                        
                        <InputBar placeholder="Book me a reservation..." />

                        <div className="flex-1"></div>
                    </div>
                )}

                {hasPrompted && (
                    <AdapterChat messages={messages} />
                )}
            </>
        );
    }
}

export default inject("NLPStore", "PromptStore")(observer(PromptFlow));