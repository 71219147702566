import Constraints from "../constraints";
import { ResponseBase, ComprehensionView } from "./base";

export class DiningResponse extends ResponseBase {
    time_range: [string, string];
    date: string;
    search_query: string;
    locale: string;
    party_size: number;
    is_nearby?: boolean;
    nearby_landmark?: string;
    is_specific_restaurant: boolean;
    notepad?: string;

    constructor(data: Partial<DiningResponse>) {
        super();
        Object.assign(this, data);
    }

    render(constraints?: Constraints): ComprehensionView {
        const _type: string[] = [];
        _type.push(this.date);
        
        this.time_range && _type.push(`At ${this.time_range[0]}`);
        this.is_specific_restaurant && _type.push(`Specific Restaurant`);

        const features: string[] = [];
        const geographicFeature = this.renderGeographicFeature(constraints);

        if (geographicFeature) {
            features.push(geographicFeature);
        } else {
            features.push(`Near ${this.locale}`);
        }

        const distanceConstraint = this.renderDistanceConstraint(constraints);
        if (distanceConstraint) {
            features.push(distanceConstraint);
        }

        return {
            attrs: {
                Type: _type,
                For: [this.party_size > 1 ? `${this.party_size} guests` : '1 guest'],
                Features: features
            }
        }
    }
}