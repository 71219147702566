import Constraints from "../constraints";
import { ResponseBase, ComprehensionView } from "./base";

export class FlightResponse extends ResponseBase {
    query: string;
    trip_type: "oneway" | "roundtrip";
    departure_airport: string[];
    destination_airport: string[];
    departure_date: string;
    num_adults: number;
    departure_time?: string;
    departure_city?: string;
    destination_city?: string;
    return_date?: string;
    return_time?: string;
    arrival_date?: string;
    arrival_time?: string;
    max_num_stops?: number;
    num_children?: number;
    cabin_class?: "economy" | "premium_economy" | "business" | "first";
    notepad?: string;

    constructor(data: Partial<FlightResponse>) {
        super();
        Object.assign(this, data);
    }

    render(constraints?: Constraints): ComprehensionView {
        const type = [this.trip_type === 'oneway' ? 'Oneway' : 'RoundTrip'];
        if (this.max_num_stops !== undefined) {
            type.push(this.max_num_stops === 0 ? 'Non-Stop' : this.max_num_stops === 1 ? 'Layover OK' : 'Layovers OK');
        }

        if (this.cabin_class) {
            type.push(this.cabin_class
                .replace(/_/g, ' ')
                .replace(/^\w/, c => c.toUpperCase()));
        }

        let departure = [...(this.departure_airport.length > 0 ? this.departure_airport : [this.departure_city ?? 'Unknown']), this.departure_date];
        let arrival = this.destination_airport.length > 0 ? this.destination_airport : [this.destination_city ?? 'Unknown'];
        if (this.arrival_date && this.arrival_date !== this.departure_date) {
            arrival.push(this.arrival_date);
        }
        if (this.arrival_time) {
            arrival.push(this.arrival_time);
        }
        let returnInfo: string[] = [];

        if (this.trip_type === 'roundtrip') {
            if (this.return_date) {
                returnInfo.push(this.return_date);
            }
            if (this.return_time) {
                returnInfo.push(this.return_time);
            }
        }

        if (constraints?.legs) {
            constraints.legs.forEach(leg => {
                if (leg.type === 'departure') {
                    if (leg.end?.event) {
                        arrival.push(`Event: ${leg.end.event.name} at ${leg.end.event.location} at ${leg.end.time.split(' ')[1]}`);
                    } else if (leg.end) {
                        arrival.push(`Arriving ${leg.end.pivot} ${leg.end.time.split(' ')[1]}`);
                    }

                    if (leg.start?.event) {
                        departure.push(`Event: ${leg.start.event.name} at ${leg.start.event.location} at ${leg.start.time.split(' ')[1]}`);
                    } else if (leg.start) {
                        departure.push(`Departing ${leg.start.pivot} ${leg.start.time.split(' ')[1]}`);
                    }
                } else if (leg.type === 'return' && this.trip_type === 'roundtrip') {
                    if (this.return_date) returnInfo.push(this.return_date);

                    if (leg.start?.event) {
                        returnInfo.push(`Event: ${leg.start.event.name} at ${leg.start.event.location} at ${leg.start.time}`);
                    } else if (leg.start) {
                        returnInfo.push(`Departing ${leg.start.pivot} ${leg.start.time}`);
                    }

                    if (leg.end?.event) {
                        returnInfo.push(`Event: ${leg.end.event.name} at ${leg.end.event.location} at ${leg.end.time}`);
                    } else if (leg.end) {
                        returnInfo.push(`Arriving ${leg.end.pivot} ${leg.end.time}`);
                    }
                }
            });
        }

        return {
            attrs: {
                Type: type,
                Departure: departure,
                Arrival: arrival,
                Passengers: [
                    ...(this.num_adults > 1 ? [`${this.num_adults} Adults`] : [`${this.num_adults} Adult`]),
                    ...(this.num_children ? [`${this.num_children} Children`] : [])
                ],
                ...(returnInfo.length > 0 && { Return: returnInfo })
            }
        };
    }
}