import React from "react";
import { inject, observer } from "mobx-react";

import AuthenticatedPage from "components/common/AuthenticatedPage";
import BasePage from 'components/common/BasePage';
import AdapterChat from "components/AdapterChat";
import withRouter from "hooks/withRouter";
import UnsupportedOption from "./options/UnsupportedOption";
import FailedOption from "./options/FailedOption";
import Card from "components/common/Card";
import Progress from "components/common/Progress";
import Notice from "components/common/Notice";
import { Job } from "models/Job";
import PromptComprehension from "components/common/PromptComprehension";
import { ComprehensionResponse } from "models/comprehension";
import OptionsTable from "./common/OptionsTable";

import FlightResponse from "./options/flight/FlightResponse";
import RestaurantResponse from "./options/restaurant/RestaurantResponse";
import HotelResponse from "./options/hotel/HotelResponse";
//import CarResponse from "./options/car/CarResponse";
//NOTE: No MemoryResponse here; look in <PromptFlow /> to explanation

import "../../styles/index.scss";

const RESPONSE_CMP = {
    'unsupported': UnsupportedOption,
    'undefined': UnsupportedOption,
    'flight_booking': FlightResponse,
    'hotel_booking': HotelResponse,
    'dining_reservation': RestaurantResponse
};

interface JobByIdProps {
    PromptStore?: any;
    router?: any;
}

class JobByIdPage extends React.Component<JobByIdProps, {}> {
    componentDidMount(): void {
        this.pollForJobUpdate();
    }

    pollForJobUpdate = () => {
        const { PromptStore, router } = this.props;

        PromptStore.getJob(router.params.jobId)
            .then((job: Job) => {
                const { job_status, type, friendly_name, error, request_payload } = job;

                // if the job already has the comprehension saved, avoid calling the API again!
                if (request_payload) {
                    PromptStore.setRawPrompt(friendly_name);
                    PromptStore.setComprehensionResponseFromJob(job);
                } else if (!PromptStore.comprehensionResponse && friendly_name && !error) {
                    PromptStore.setRawPrompt(friendly_name);
                    PromptStore.parseInput();
                }

                if (
                    type !== 'unsupported' &&
                    (job_status === 'pending' || job_status === 'in_progress')
                ) {
                    // check for updates every 10 seconds
                    setTimeout(this.pollForJobUpdate, 10_000);
                }
            });
    }

    formatAgentResponse = () => {
        const { PromptStore } = this.props;
        const { results, type, job_status }: Job = PromptStore.currentJob;

        const CMP = RESPONSE_CMP[type] || RESPONSE_CMP.unsupported;

        if (!type || !job_status || type === 'unsupported') {
            return (<UnsupportedOption />);
        }

        if (job_status === 'failed') {
            return (<FailedOption job={PromptStore.currentJob} />);
        }

        if (job_status === 'pending' || job_status === 'in_progress') {
            return (<Progress />);
        }

        // @ts-ignore
        return (<CMP results={results} />);
    }

    render() {
        const { PromptStore } = this.props;
        const messages = [];

        messages.push({
            name: 'You',
            time: PromptStore.currentJob?.request_timestamp || Date.now(),
            loading: false,
            content: (
                <Card>
                    <p>{PromptStore.currentJob?.friendly_name || PromptStore.rawPrompt}</p>
                </Card>
            )
        });

        if (PromptStore.loadingParseInput) {
            messages.push({
                name: 'Adapter',
                time: PromptStore.currentJob?.request_timestamp || Date.now(),
                loading: true,
                content: (<></>)
            });
        } else if (PromptStore.comprehensionResponse) {
            // must be comp_v2
            if (PromptStore.comprehensionResponse?.getType) {
                const response = new ComprehensionResponse(PromptStore.comprehensionResponse);
                switch (response.getType()) {
                    case 'other':
                        messages.push({
                            name: 'Adapter',
                            time: PromptStore.currentJob?.request_timestamp || Date.now(),
                            loading: false,
                            content: (<UnsupportedOption />)
                        });
                        break;

                    default:
                        console.log('default "type": ', response.getType());
                        messages.push({
                            name: 'Adapter',
                            time: PromptStore.currentJob?.request_timestamp || Date.now(),
                            loading: false,
                            content: (<PromptComprehension />)
                        });
                        break;
                }
            }
            // must be comp_v1
            else {
                switch (PromptStore.comprehensionResponse.prompt_category) {
                    case 'other':
                        messages.push({
                            name: 'Adapter',
                            time: PromptStore.currentJob?.request_timestamp || Date.now(),
                            loading: false,
                            content: (<UnsupportedOption />)
                        });
                        break;

                    default:
                        messages.push({
                            name: 'Adapter',
                            time: PromptStore.currentJob?.request_timestamp || Date.now(),
                            loading: false,
                            content: (<OptionsTable />)
                        });
                        break;
                }
            }
        }

        if (PromptStore.comprehensionResponse && PromptStore.currentJob) {
            if (!PromptStore.currentJob.error) {
                messages.push({
                    name: 'Adapter',
                    time: PromptStore.currentJob?.updated_timestamp || Date.now(),
                    loading: false,
                    content: this.formatAgentResponse()
                });
            } else {
                messages.push({
                    name: 'Adapter',
                    time: PromptStore.currentJob?.request_timestamp || Date.now(),
                    loading: false,
                    content: (
                        <>
                            <Notice error text="Request Failed" />
                            <p>{PromptStore.currentJob.error}</p>
                        </>
                    )
                });
            }
        } else if (PromptStore.comprehensionResponse && !PromptStore.currentJob) {
            messages.push({
                name: 'Adapter',
                time: PromptStore.currentJob?.request_timestamp || Date.now(),
                loading: true,
                content: (<></>)
            });
        }

        return (
            <AuthenticatedPage>
                <BasePage>
                    <AdapterChat messages={messages} />
                </BasePage>
            </AuthenticatedPage>
        );
    }
}

export default withRouter(inject("PromptStore")(observer(JobByIdPage)));
