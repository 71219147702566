import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { Auth0Provider } from '@auth0/auth0-react';
import reportWebVitals from "./reportWebVitals";
import config from "config";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <Auth0Provider
    domain={window.REACT_APP_AUTH0_DOMAIN ?? config.auth0.domain}
    clientId={window.REACT_APP_AUTH0_CLIENT_ID ?? config.auth0.clientId}
    authorizationParams={{
      redirect_uri: `${window.location.origin}${window.REACT_APP_REDIRECT_URI ?? '/callback'}`,
      audience: window.REACT_APP_AUTH0_AUDIENCE ?? 'https://adapter.com',
    }}
  >
    <App />
  </Auth0Provider>,
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
