import React from 'react';

import FlightOption from './FlightOption';
import { PurpleCircleIcon } from "components/common/Icon";

const FlightResponse = ({ results }) => {
    const options = [];

    if (Array.isArray(results)) {
        for (let i = 0; i < results.length; i++) {
            options.push(
                <FlightOption 
                    key={`option-${i}`} 
                    option={results[i]} 
                    isTopPick={i === 0} 
                    allOptions={results}
                />
            );
        }
    }

    if (options.length === 0) {
        return (
            <>
                <p>
                    Adapter could not find any results that match your request.
                    Please try adjusting your your request to find more options.
                </p>

                <p>
                    Helpful Tips:
                </p>

                <ul>
                    <li>- Expand the time window of your search</li>
                    <li>- Allow for more stops or alternative cabin classes</li>
                    <li>- Include additional airports</li>
                </ul>
            </>
        );
    }

    return (
        <>
            <p>
                Adapter found {options.length} option(s) and ranked them based on your needs and preferences, including:
            </p>

            <div className="agent-response">
                <div><PurpleCircleIcon />Timeliness</div>
                <div><PurpleCircleIcon />Cost sensitivity</div>
                <div><PurpleCircleIcon />Stops</div>
                <div><PurpleCircleIcon />Preferred airline</div>
            </div>

            {options}
        </>
    );
};

export default FlightResponse;