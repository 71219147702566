import React from 'react';
import { Job } from "models/Job";
import Notice from 'components/common/Notice';

const FailedOption = ({ job }: { job: Job }) => {
    return (
        <>
            <Notice text='Request Failed' error />

            <div>
                An internal error occurred while processing your request. Please try again in a few minutes.

                If the problem continues, please reach out to customer support.
            </div>
        </>
    );
};

export default FailedOption;