import React from "react";

export default class FourOhFourPage extends React.Component {

  render() {
    return (
      <div className="adapter-basepage">
        <div className="adapter-basepage-inner">
          <div className="adapter-fullscreen-content">

            <div className="flex-1"></div>

            <h1>Well this is awkward...</h1>

            <p>Despite our best efforts, we&apos;re not really sure how you got here.</p>

            <p>Maybe you'd like to return to the <a href="/dashboard">Dashboard</a>?</p>

            <div className="flex-1"></div>
          </div>
        </div>
      </div>
    );
  }
}
