import { makeObservable, observable, action, computed } from "mobx";
import { toast } from "react-toastify";

import runGoogleOAuthFlow, { getGoogleConfigs } from "util/GoogleOAuth";
import { delay, exponentialBackoff } from "util/ExponentialBackoff";
import { getFromLocalstorage, KEYS, deleteFromLocalStorage, saveToLocalstorage } from "util/Localstorage";

import API from "util/API";

class OnboardingStore {
    public isPolling: boolean = false;
    public isOnboarding: boolean = false;

    public hasConnectedAccount: boolean = false;
    public currentPage: number = 0;
    
    public isLoading: boolean = false;
    public ingestedEmailCount: number = 0;
    public routedEmailCount: number = 0;
    public flightExtractionCount: number = 0;
    public hotelStayExtractionCount: number = 0;
    public otherCategoriesIngested: number = 0;
    public totalEmails: number = 0;
    public processedEmails: number = 0;
    public flightExtractions: number = 0;
    public hotelStayExtractions: number = 0;
    public userCreated: boolean = false;
    public onboardingCompleted: boolean = false;

    constructor() {
        makeObservable(this, {
            isOnboarding: observable,

            hasConnectedAccount: observable,
            currentPage: observable,

            ingestedEmailCount: observable,
            routedEmailCount: observable,
            flightExtractions: observable,
            hotelStayExtractions: observable,
            totalEmails: observable,
            otherCategoriesIngested: observable,
            onboardingCompleted: observable,
            userCreated: observable,

            handleGoogleAuth: action,
            completeOnboarding: action,
            setConnectedState: action,
            setPage: action,
            getOnboardingStatsSuccess: action,
            setOauthState: action,
            pollForStats: action,
            stopPollingForStats: action,

            persistedState: computed,
        });

        // @ts-ignore
        const persistedState: any = getFromLocalstorage(KEYS.onboardingState);
        this.isOnboarding = !!persistedState;
        this.currentPage = persistedState?.currentPage || 0;
        this.hasConnectedAccount = persistedState?.hasConnectedAccount || false;
    }

    public get persistedState() {
        return {
            hasConnectedAccount: this.hasConnectedAccount,
            currentPage: this.currentPage
        };
    }

    completeOnboarding = () => {
        this.isOnboarding = false;
        deleteFromLocalStorage(KEYS.onboardingState);
        window.location.reload();
    }

    setConnectedState = () => {
        this.hasConnectedAccount = true;
        saveToLocalstorage(KEYS.onboardingState, this.persistedState);
    }

    setOauthState = () => {
        this.currentPage += 1;
        saveToLocalstorage(KEYS.onboardingState, this.persistedState);
    }

    setPage = (page: number) => {
        this.currentPage = page;
        saveToLocalstorage(KEYS.onboardingState, this.persistedState);
        
        // if the screen elements have scrolled, reset the scroll location to top
        document.querySelector('.adapter-basepage-inner')
            ?.scrollTo({ top: 0, behavior: 'smooth' })
    }

    handleGoogleAuth = (
        gmailCategoryState: string[],
        gmailTimeState: string = '',
        accountEmail: string = undefined
    ) => {
        const [allowedGmailCategories, gmailStartingAfter] = getGoogleConfigs(gmailCategoryState, gmailTimeState);

        const requestBody = {
            gmail_categories: allowedGmailCategories,
            gmail_starting_after: gmailStartingAfter,
            accountEmail,
            timeOption: gmailTimeState
        };

        runGoogleOAuthFlow(requestBody);
    };

    getOnboardingStatsSuccess = (data: any) => {
        this.isLoading = false;

        this.totalEmails = data.totalEmails;
        this.processedEmails = data.processedEmails;
        this.flightExtractions = data.flightExtractions;
        this.hotelStayExtractions = data.hotelStayExtractions;
        this.userCreated = data.userCreated;
        this.onboardingCompleted = data.onboardingCompleted;
    };

    getOnboardingStatsFailure = (error) => {
        this.isLoading = false;
    };

    getOnboardingStats = () => {
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            API.get("/api/user/onboardingStats")
                .then(response => response.json())
                .then(data => {
                    this.getOnboardingStatsSuccess(data);
                    return resolve(data);
                })
                .catch((error) => {
                    this.getOnboardingStatsFailure(error);
                    return reject(error);
                });
        });
    };

    stopPollingForStats = () => {
        this.isPolling = false;
    }

    pollForStats = async () => {
        this.isPolling = true;

        await exponentialBackoff(this.getOnboardingStats, 4)
            .then(({ onboardingCompleted }: any) => {
                if (onboardingCompleted) {
                    this.isPolling = false;
                } else if (this.isPolling) {
                    delay(3_000).then(this.pollForStats);
                }
            })
            .catch(e => {
                console.error(e);
                toast.error('An error occurred during account onboarding.');
            });
    }
}

const onboardingStore = new OnboardingStore();

export default onboardingStore;
