import React from "react";
import LeftNav from "../LeftNav";
import Footer from "../Footer";

export default function BasePage({ children, footer = false }: any) {
  return (
    <>
      <div className="adapter-basepage">
        <LeftNav />
        <div className="adapter-basepage-inner">
          {children}
          <Footer copy={footer} />
        </div>
      </div>
    </>
  );
}
