import React from 'react';

import './Button.scss';

type ButtonProps = {
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  onClick?: () => void;
  children: any;
  size?: "2xl" | "small";
  style?: any;
};

const Button = ({ children, disabled, type, size, onClick, style }: ButtonProps) => (
  <button
    className={`adapter-button ${size && 'size-' + size}`}
    type={type}
    disabled={disabled}
    onClick={onClick}
    style={style}
  >
    {children}
  </button>
);

export default Button;