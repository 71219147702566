import React, { useState } from 'react';
import Button from "components/common/Button";
import StepHeader from './StepHeader';

const Trust = ({ onNextPage }) => {
    const [hasAccepted, setHasAccepted] = useState(false);

    return (
        <>
            <StepHeader currentStep={1} />

            <div className="adapter-two-col">

                <div className="adapter-two-col--title">
                    <div className="flex-1"></div>

                    <h1>
                        Adapter&apos;s Commitment to Trust and Transparency
                    </h1>

                    <div className="flex-1"></div>
                </div>

                <div className="adapter-two-col--content">
                    <div className="flex-1"></div>

                    <p>
                        At Adapter, our mission is to empower you. To do so, we need personal information from you.&nbsp;
                        We don&apos;t take this responsibility lightly. Earned trust, privacy, and transparency are pillars of the Adapter experience.
                    </p>

                    <p>
                        We aim to lead with an individual-centric philosophy that emphasizes:
                    </p>

                    <ul>
                        <li>Robust privacy measures</li>
                        <li>Clear consent processes</li>
                        <li>Open communication about how your information is collected, used, disclosed and stored</li>
                    </ul>

                    <p>
                        <input
                            type="checkbox"
                            onChange={v => setHasAccepted(v.target.checked)}
                            style={{ cursor: 'pointer' }}
                        />&nbsp;
                        I agree to Adapter&apos;s <a href="https://www.adapter.com/tos">Privacy Policy</a>
                    </p>

                    <Button
                        onClick={onNextPage}
                        size="2xl"
                        disabled={!hasAccepted}
                        style={{ alignSelf: 'end' }}
                    >
                        Continue &rarr;
                    </Button>

                    <div className="flex-1"></div>
                </div>
            </div>
        </>
    );
}

export default Trust;