import React from "react";

export default class AccessDeniedPage extends React.Component {

  render() {
    return (
      <div className="adapter-basepage">
        <div className="adapter-basepage-inner">
          <div className="adapter-fullscreen-content">
            <div className="flex-1"></div>

                <h1>Access Denied</h1>

                <p>Sorry, you don&apos;t have permission to view this page.</p>

                <p>If you just created an account, you must first verify your email in order to login.</p>

                <p>Please contact <a href="mailto:support@adapter.com">support@adapter.com</a> if you think this is a mistake.</p>

                <p>You can return to the <a href="https://www.adapter.com">Adapter Homepage</a> or go back to <a href="/login">Login</a>.</p>

            <div className="flex-1"></div>
          </div>
        </div>
      </div>
    );
  }
}
