import React, { useState } from 'react';
import AccountDisplay from 'pages/settings/AccountDisplay';
import StepHeader from './StepHeader';
import GrantDataAccessModal from '../modals/GrantDataAccessModal';
import DataHandlingModal from '../modals/DataHandlingModal';

const DataControls = ({ onNextPage }) => {
    const [grantDataAccessOpen, setGrantDataAccessOpen] = useState(false);
    const [callbackFn, setCallbackFn] = useState(undefined);
    const [ dataHandlingOpen, setDataHandlingOpen ] = useState(false);

    return (
        <>
            <StepHeader currentStep={3} />

            <div className="adapter-two-col">

                <div className="adapter-two-col--title">
                    <div className="flex-1"></div>

                    <h2>
                        Set your data controls
                    </h2>

                    <p>
                        Adapter allows you to aggregate and leverage your data using Adapter&apos;s tools. 
                        The wider the range of data you share, the more helpful Adapter can be.
                    </p>

                    <p>
                        Adapter automatically sorts data into multiple categories. Then it processes data to 
                        identify events, insights, and preferences relevant to a user. You choose which categories 
                        and date range Adapter looks at when processing your data. You are in control of the data 
                        sources and categories you choose to share and can revoke access at any time.
                    </p>

                    <p>
                        <a onClick={() => setDataHandlingOpen(true)}>Learn how data filtering works</a>
                    </p>

                    <div className="flex-1"></div>
                </div>

                <div className="adapter-two-col--content">
                    <div className="flex-1"></div>

                    <AccountDisplay
                        hideIcon
                        buttonText='Connect Google Account'
                        beforeSubmit={(fn) => {
                            setGrantDataAccessOpen(true);

                            // https://stackoverflow.com/a/55621325/551436
                            // yes, function-returns-inner-function looks weird but React hooks are kinda weird
                            setCallbackFn(() => () => fn());
                        }}
                    />

                    <div className="flex-1"></div>
                </div>
            </div>

            {dataHandlingOpen && <DataHandlingModal onClose={() => setDataHandlingOpen(false)} />}

            {grantDataAccessOpen && (
                <GrantDataAccessModal
                    onContinue={() => callbackFn()}
                    onClose={() => setGrantDataAccessOpen(false)}
                />
            )}
        </>
    );
}

export default DataControls;