import Constraints from './constraints';
import { PromptMetadata } from './metadata';

import { ResponseBase, ComprehensionView } from './response/base';
import { HotelResponse } from './response/HotelResponse';
import { FlightResponse } from './response/FlightResponse';
import { DiningResponse } from './response/DiningResponse';
import { MemoryResponse } from './response/MemoryResponse';
import { UnsupportedResponse } from './response/UnsupportedResponse';

export type Operation = 'create' | 'read' | 'update' | 'delete';
export type Target = 'flight' | 'hotel' | 'dining' | 'memory' | 'lodging';

export class ComprehensionResponse {
    uuid: string = null;
    job_id?: string = null;
    trigger_event_id?: string = null;

    operation: Operation;
    target: Target;

    constraints: Constraints = null;
    metadata: PromptMetadata = null;

    typed_response: ResponseBase;

    constructor(data: {
        operation: Operation;
        target: Target;
        constraints: Constraints;
        summary: any;
        uuid?: string;
        job_id?: string;
        trigger_event_id?: string;
        metadata?: PromptMetadata;
    }) {
        if (Array.isArray(data.operation)) {
            this.operation = data.operation[0];
            this.target = data.operation[1];
        } else {
            this.operation = data.operation;
            this.target = data.target;
        }

        this.constraints = new Constraints(data.constraints);
        this.metadata = data.metadata;
        this.uuid = data.uuid;
        this.job_id = data.job_id;
        this.trigger_event_id = data.trigger_event_id;

        this.typed_response = this.deserializeResponse(data.summary);
    }

    private deserializeResponse(summary: any): ResponseBase {
        switch (this.target) {
            case 'flight':
                return new FlightResponse(summary);
            case 'hotel':
                return new HotelResponse(summary);
            case 'lodging':
                return new HotelResponse(summary);
            case 'dining':
                return new DiningResponse(summary);
            case 'memory':
                return new MemoryResponse(summary);
            default:
                return new UnsupportedResponse({
                    action: 'read',
                    response: 'Adapter did not understand your request.'
                });
        }
    }

    public getType(): string {
        return `${this.operation}_${this.target}`;
    }

    public render(): ComprehensionView {
        return this.typed_response.render(this.constraints);
    }

    public getTypedResponse(): ResponseBase {
        return this.typed_response;
    }
}