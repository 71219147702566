import React from 'react';
import { inject, observer } from 'mobx-react';

import { Message } from "components/common/Chat/Message";
import Progress from './common/Progress';
import InputBar from 'pages/intentions/common/InputBar';
import NLPInputBar from 'pages/intentions/common/NLPInputBar';
import PromptToggle from 'pages/dashboard/prompt/PromptToggle';
import { AdapterChatMessage } from 'models/Chat';

import "./AdapterChat.scss";

type AdapterChatProps = {
    messages: AdapterChatMessage[];
    NLPStore?: any;
}

type AdapterChatState = {}

class AdapterChat extends React.Component<AdapterChatProps, AdapterChatState> {
    formatMessages = () => {
        const { messages } = this.props;

        const allMsg = [];

        for (let i=0; i<messages.length; i++) {
            const { name, time, content, loading } = messages[i];

            allMsg.push(
                <Message
                    key={i}
                    name={name}
                    time={time}
                    content={loading ? <Progress /> : content}
                />
            );
        }

        return allMsg;
    }

    render() {
        const { NLPStore } = this.props;
        const messages = this.formatMessages();

        return (
            <div className="adapter-chat">
                <div className="prompt-toggle-wrapper">
                    <PromptToggle />
                </div>
                
                <h2>My Intentions</h2>

                <div className="adapter-chat-wrapper">
                    {NLPStore.isCurrentTab
                        ? <NLPInputBar cls="persist" placeholder="Tell me about my data..." />
                        : <InputBar cls="persist" placeholder="Book me a reservation..." />
                    }

                    {messages}
                </div>
            </div>
        );
    }
}

export default inject("NLPStore")(observer(AdapterChat));