import React, { useState } from 'react';

import {
    OneWayFlight, 
    RoundTripFlight
} from './FlightOption';

import FlightOptionUtilityTooltip from './FlightOptionUtilityTooltip';

const FlightOptionUtilityBar = ({ option, allOptions }) => {
    const [ activeTooltip, setActiveTooltip ] = useState(null);

    if (!allOptions || allOptions.length === 0) {
        return null;
    }

    const getUtilityCost = (opt: OneWayFlight | RoundTripFlight) => {
        if ('utility_costs' in opt && opt.utility_costs) {
            return opt.utility_costs.total_costs;
        } else if ('departure_option' in opt && opt.departure_option.utility_costs) {
            return opt.departure_option.utility_costs.total_costs;
        }
        return 0;
    };

    const utilityValues = allOptions.map(getUtilityCost);
    const currentUtilityValue = getUtilityCost(option);

    const sortedValues = [...utilityValues].sort((a, b) => b - a);
    const maxValue = sortedValues[0];
    const minValue = sortedValues[sortedValues.length - 1];

    const normalizeValue = (value: number) => {
        return 100 - ((value - minValue) / (maxValue - minValue) * 100);
    };

    // Separate current option from other options
    const otherOptions = allOptions.filter(opt => getUtilityCost(opt) !== currentUtilityValue);

    return (
        <div className="flightOptionUtilityBarContainer">
            <h3 className="utilityBarTitle">
                Match Comparison:
            </h3>

            <p className="subTitle">
                How this flight compared to the other results found
            </p>

            <div className="utilityBar">
                {otherOptions.map((opt, index) => {
                    const value = getUtilityCost(opt);
                    const left = `${normalizeValue(value)}%`;
                    return (
                        <div className="dotContainer" key={index} style={{ left }}>
                            <div
                                className="dot"
                                onMouseEnter={() => setActiveTooltip(index)}
                                onMouseLeave={() => setActiveTooltip(null)}
                            />
                            {activeTooltip === index && <FlightOptionUtilityTooltip option={opt} allOptions={allOptions} />}
                        </div>
                    );
                })}
                {/* Render the current option's dot last */}
                <div className="dotContainer" style={{ left: `${normalizeValue(currentUtilityValue)}%` }}>
                    <div
                        className="dot highlighted"
                        onMouseEnter={() => setActiveTooltip(allOptions.length)}
                        onMouseLeave={() => setActiveTooltip(null)}
                    />
                    {activeTooltip === allOptions.length && <FlightOptionUtilityTooltip option={option} allOptions={allOptions} />}
                </div>
            </div>

            <div className="labelContainer">
                <span className="label">Worst</span>
                <span className="label">Best</span>
            </div>
        </div>
    );
};

export default FlightOptionUtilityBar;