import Constraints from '../constraints';

export type LiteralType<T extends string> = T;

export interface ComprehensionView {
    text?: string;
    attrs?: { [key: string]: string[] };
}

export abstract class ResponseBase {
    abstract render(constraints?: Constraints): ComprehensionView;

    protected renderGeographicFeature(constraints?: Constraints): string {
        if (constraints?.geographic) {
            const { pivot, name, address } = constraints.geographic;
            let geographicDesc = '';

            if (address && name) {
                geographicDesc = `${address} (${name})`;
            } else if (address) {
                geographicDesc = address;
            } else if (name) {
                geographicDesc = name;
            }

            const pivotTerm = pivot.toLowerCase() === 'nearby' ? 'near' : pivot;
            return geographicDesc ? `${pivotTerm} ${geographicDesc}` : pivotTerm;
        }
        return '';
    }

    protected renderDistanceConstraint(constraints?: Constraints): string {
        if (constraints?.distance) {
            return `Within ${constraints.distance.value} ${constraints.distance.unit} by ${constraints.distance.method}`;
        }
        return '';
    }
}