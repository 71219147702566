import React, { ChangeEvent } from "react";
import { Link } from "react-router-dom";

import './Input.scss';

type ButtonConfig = {
    title: string;
    color: string;
    disabled?: boolean;
}

export type InputWithIconButtonProps = {
    labelText?: string | any;
    fieldId: string;

    value?: string | number;
    placeholder?: string;
    hintText?: string;
    disabled?: boolean;
    required?: boolean;

    errorText?: string;
    error?: boolean;

    buttonConfig?: ButtonConfig;

    link?: {
        linkText: string;
        linkUrl: string;
    };

    Icon: any;

    onChange: (event: ChangeEvent<any>) => void;
    onClick: () => void;
    onEnterKey: () => void;

    style?: any;
    darkMode?: boolean;
};

export default class InputWithIconButton extends React.Component<InputWithIconButtonProps, any> {
    inputRef: any = null;

    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.value === this.props.value && nextProps.buttonConfig.disabled === this.props.buttonConfig.disabled) {
          // Nothing has changed, so a re-render is unnecessary
          return false;
        }

        setTimeout(() => this.handleResize(false), 200);
        return true;
      }

    handleResize = (e: any) => {
        const { onChange } = this.props;

        const ref = this.inputRef.current;

        if (ref?.style) {
            ref.style.height = '0px';
            ref.style.height = ref.scrollHeight + 'px';
        }

        if (e !== false) {
            onChange(e);
        }
    }

    onKeyDown = (e: any) => {
        const { onEnterKey } = this.props;
        // if enter key...
        if (e.keyCode === 13) {
            e.preventDefault();
            onEnterKey();
        }
    }

    renderTextInput = () => {
        const {
            fieldId,
            value,
            placeholder,
            errorText,
            error,
            disabled = false,
            Icon,
            buttonConfig = { 
                title: 'Submit',
                color: '#7549F2',
                disabled: false
            },
            onClick
        } = this.props;

        return (
            <div className="inputWithIconButton">
                <textarea
                    ref={this.inputRef}
                    id={fieldId}
                    className={errorText || error ? 'error' : ''}
                    name={fieldId}
                    onChange={this.handleResize}
                    value={value ?? ''}
                    placeholder={placeholder}
                    disabled={disabled}
                    onKeyDown={this.onKeyDown}
                />
                <Icon
                    color={buttonConfig.color} 
                    title={buttonConfig.title}
                    ariaRole="button" 
                    onClick={!error && !errorText && !buttonConfig.disabled ? onClick : () => { }} 
                />
            </div>
        );
    }

    render() {
        const {
            fieldId,
            hintText,
            errorText,
            error,
            labelText,
            link,
            style = {},
            darkMode = false
        } = this.props;

        return (
            <div className={`adapter-form-field-input ${darkMode ? 'darkMode' : ''}`} style={style}>
                {labelText && (
                    <label htmlFor={fieldId}>
                        {labelText}
                    </label>
                )}

                {this.renderTextInput()}

                {link && (
                    <div className="text-sm text-right" style={{ width: '100%' }}>
                        <Link className="font-semibold" to={link.linkUrl}>
                            {link.linkText}
                        </Link>
                    </div>
                )}

                {!errorText && hintText && (
                    <p className="hintText">{hintText}</p>
                )}

                {errorText && (
                    <p className="errorText">{errorText}</p>
                )}
            </div>
        );
    }
}