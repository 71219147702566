import React from "react";
import { inject, observer } from "mobx-react";
import { toast } from "react-toastify";

import AuthenticatedPage from "components/common/AuthenticatedPage";

import Onboarding from "pages/onboarding";
import GettingStarted from "./GettingStarted";

import "../../styles/index.scss";

interface DashboardProps {
    OnboardingStore?: any;
    UserStore?: any;
}

class DashboardPage extends React.Component<DashboardProps, {}> {
    componentDidMount(): void {
        const { UserStore } = this.props;

        UserStore.loadUser()
            .then(
                () => UserStore.loadUserAccounts()
                    .then(({ connectedAccounts }) => {
                        if (connectedAccounts?.length > 0 && connectedAccounts?.some((account) => account.connected === false)) {
                            toast.error(
                                (
                                    <div>Your account is not connected. <a href='/settings/accounts'>Reconnect now</a>.</div>
                                ),
                                { theme: 'dark', position: 'bottom-right', icon: false }
                            );
                        }
                    })
            );
    }

    render() {
        const { OnboardingStore, UserStore } = this.props;

        // onboarding steps make API requests to update the user; don't show a blank screen!
        if (UserStore.isLoading && !OnboardingStore.isOnboarding) {
            return <></>;
        }

        if (UserStore.userAccounts.length === 0 || OnboardingStore.isOnboarding) {
            return (
                <Onboarding />
            );
        }

        return (
            <AuthenticatedPage>
                <GettingStarted />
            </AuthenticatedPage>
        );
    }
}

export default inject("OnboardingStore", "UserStore")(observer(DashboardPage));
