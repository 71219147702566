import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { inject, observer } from 'mobx-react';

interface CallbackPageProps {
    AuthStore?: any;
  }
  
  const CallbackPage: React.FC<CallbackPageProps> = ({ AuthStore }) => {
    const navigate = useNavigate();
    const { handleRedirectCallback, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const handleAuthCallback = async () => {
            try {
                await handleRedirectCallback();
                const accessToken = await getAccessTokenSilently();
                await AuthStore.signIn({'user_access_token': accessToken});
                navigate('/dashboard');
            } catch (e: any) {
                if (e.error === 'allowlist_deny') {
                    setError('Access denied: Your email is not on the allowlist.');
                } else {
                    setError('An unexpected error occurred. Please try again.');
                }
                navigate('/access-denied');
            }
        };

        if (!isAuthenticated) {
            handleAuthCallback();
        }
    }, [isAuthenticated, navigate]);

    return (
        <div className="adapter-basepage">
        <div className="adapter-basepage-inner">
          <div className="adapter-fullscreen-content">
            <div className="flex-1"></div>

                <h1>Loading...</h1>

            <div className="flex-1"></div>
          </div>
        </div>
      </div>
    );
};

export default inject('AuthStore')(observer(CallbackPage));
