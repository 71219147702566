import React from "react";
import { Provider } from "mobx-react";

import atlasStore from "stores/AtlasStore";
import authStore from "stores/AuthStore";
import onboardingStore from "stores/OnboardingStore";
import nlpStore from "stores/NLPStore";
import promptStore from "stores/PromptStore";
import userStore from "stores/UserStore";

const StoreWrapper = ({ children }: any) => {
  return (
    <Provider
      AtlasStore={atlasStore}
      AuthStore={authStore} 
      OnboardingStore={onboardingStore}
      NLPStore={nlpStore}
      PromptStore={promptStore}
      UserStore={userStore}
    >
      {children}
    </Provider>
  );
};

export default StoreWrapper;
