import React, { ChangeEvent } from "react";
import ReactSelect from "react-select";
import { Link } from "react-router-dom";

import './Input.scss';

export type InputProps = {
    labelText?: string | any;
    fieldId: string;

    value?: string | number;
    type?: string; // "text" | "textarea" | "password" | "email";
    placeholder?: string;
    hintText?: string;
    disabled?: boolean;
    required?: boolean;

    errorText?: string;
    error?: boolean;

    selectConfig?: any;

    link?: {
        linkText: string;
        linkUrl: string;
    };

    onChange: (event: ChangeEvent<any>) => void;

    style?: any;
    darkMode?: boolean;
};

export default class Input extends React.Component<InputProps, any> {
    renderSelect = () => {
        const {
            fieldId,
            value,
            selectConfig,
        } = this.props;

        return (
            <ReactSelect
                id={fieldId}
                className="relative"
                name={fieldId}
                value={value}
                {...selectConfig}
            />
        );
    }

    renderTextInput = () => {
        const {
            fieldId,
            type = 'text',
            value,
            placeholder,
            errorText,
            error,
            disabled = false,
            onChange,
        } = this.props;

        return (
            <input
                id={fieldId}
                className={errorText || error ? 'error' : ''}
                name={fieldId}
                type={type}
                onChange={onChange}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
            />
        );
    }

    renderTextarea = () => {
        const {
            fieldId,
            value,
            placeholder,
            errorText,
            error,
            disabled = false,
            onChange,
        } = this.props;

        return (
            <textarea
                id={fieldId}
                className={errorText || error ? 'error' : ''}
                name={fieldId}
                onChange={onChange}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
            ></textarea>
        );
    }

    render() {
        const {
            fieldId,
            type = 'text',
            hintText,
            errorText,
            error,
            labelText,
            link,
            style = {},
            darkMode = false
        } = this.props;

        return (
            <div className={`adapter-form-field-input ${darkMode ? 'darkMode' : ''}`} style={style}>
                {labelText && (
                    <label htmlFor={fieldId}>
                        {labelText}
                    </label>
                )}

                {(() => {
                    switch (type) {
                        case 'textarea':
                            return this.renderTextarea();

                        case 'select':
                            return this.renderSelect();

                        default:
                            return this.renderTextInput();
                    }
                })()}

                {link && (
                    <div className="text-sm text-right" style={{ width: '100%' }}>
                        <Link className="font-semibold" to={link.linkUrl}>
                            {link.linkText}
                        </Link>
                    </div>
                )}

                {!errorText && hintText && (
                    <p className="hintText">{hintText}</p>
                )}

                {errorText && (
                    <p className="errorText">{errorText}</p>
                )}
            </div>
        );
    }
}