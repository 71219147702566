import React from 'react';
import L from "leaflet";

import Button from 'components/common/Button';
import Pill from 'components/common/Pill';

import './RestaurantOption.scss';

const MAP_LOCATION_COLOR = '#EF6820';

function convertTo12Hour(time24: string): string {
    const [hours, minutes] = time24.split(':');
    const hour = parseInt(hours, 10);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12;
    return `${hour12}:${minutes} ${ampm}`;
}

type RestaurantResult = {
    booking_url?: string;
    time: string;
    option: {
        address: string;
        name: string;
        order_online_url: string;
        place_id: string;
        price: string;
        rating: number;
        reserve_source: string;
        reserve_url: string;
        reviews: number;
        serp_idx: number;
        time: string;
        type: string;
        types: string[];

        website: string;

        gps_coordinates: {
            lat: number;
            lng: number;
        }
    }
}

type RestaurantOptionProps = {
    option: RestaurantResult;
    isTopPick: boolean;
    allOptions: RestaurantResult[];
};

class RestaurantOption extends React.Component<RestaurantOptionProps, {}> {
    private mapRef = null;

    state = {
        map: null
    }

    constructor(props) {
        super(props);

        this.state = {
            map: null
        };

        this.mapRef = React.createRef();
    }

    componentDidMount(): void {
        this.drawMap();
    }

    drawMap = () => {
        const { option } = this.props;
        const restaurantDetails = option.option;
        const { lat, lng } = restaurantDetails?.gps_coordinates;

        // Check if the map has already been initialized
        if (!this.state.map && lat && lng) {
            let zoomScale = 16;

            // Initialize the map
            const initializedMap = L.map(this.mapRef.current, {
                zoomControl: false,
                attributionControl: false,
            }).setView([lat, lng], zoomScale);

            const tileLayer = L.tileLayer(
                "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            ).addTo(initializedMap);

            tileLayer.getContainer().style.filter = "grayscale(100%)";

            L.svg({ clickable: true }).addTo(initializedMap);

            L.circleMarker([lat, lng], {
                color: MAP_LOCATION_COLOR,
                fillColor: MAP_LOCATION_COLOR,
                fillOpacity: 0.5,
                radius: 4 // Fixed pixel radius
            }).addTo(initializedMap);

            // Store the map object in state to avoid reinitialization
            this.setState({ map: initializedMap });
        }
    };

    render() {
        const { option, isTopPick, allOptions } = this.props;
        const restaurantDetails = option.option;
        const { lat, lng } = restaurantDetails?.gps_coordinates;

        return (
            <div className="adapter-intention-option">
                <div className="option-price-header">
                    <div className="option-price-header-wrapper">
                        <h3>{restaurantDetails.name}</h3>
                        <p>
                            <a href={restaurantDetails.website} target="_blank">
                                {restaurantDetails.website}
                            </a>
                        </p>
                    </div>
                    {isTopPick && <Pill highlighted>Best Match</Pill>}
                </div>

                <div className="option-details">
                    <div className="option-details-title-wrapper">
                        { restaurantDetails.time && (<div>Time: {convertTo12Hour(restaurantDetails.time)}</div>) }
                        <div>Price: {restaurantDetails.price}</div>
                        <div>Rating: {restaurantDetails.rating}</div>
                        <div># Reviews: {restaurantDetails.reviews}</div>
                    </div>

                    <p>{restaurantDetails.address}</p>

                    {lat && lng && (
                        <div className="option-details-body-wrapper">
                            <div ref={this.mapRef} style={{ height: '300px', flex: 1 }}></div>
                        </div>
                    )}
                </div>


                {option.booking_url && (
                    <Button
                        size="2xl"
                        onClick={() => window.open(option.booking_url, '_blank')}
                        style={{ width: '100%', marginTop: '24px' }}
                    >Book Now</Button>
                )}
            </div>
        );
    }
}

export default RestaurantOption;